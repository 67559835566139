import React from "react"
import Layout from "../components/Layout/layout"
import HomePageTextContents from "../components/HomePageTextContent/HomePageTextContent"

export default function Home() {
  return (
    <Layout title="Home">

      <HomePageTextContents/>

      <div style={endPageSpacer}>
        {/*  TMP Spacer whilst there is no further content*/}
      </div>

    </Layout>

  )
}

const endPageSpacer = {
  height: "5vh"
}

