// Library imports
import React from 'react';
import { Link } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid'

// Helpers
import { contactLinks } from "./data";

// Components

// Styles
import styles from './Footer.module.scss';

// Assets


const Footer = () => (
    <footer className={styles.Footer}>
        <Grid fluid className={styles.footerContent}>
            <Row>
                <Col xs={12} md={3}>
                </Col>
                <Col xs={12} md>
                    <div className={styles.navListContainer}>
                        <h4>Contact</h4>
                        <ul className={styles.list}>
                            { contactLinks.map(({label, link}) => (
                              <li key={label}><a href={`mailto:${link}`}>{label}</a></li>
                            ))}
                        </ul>
                    </div>
                </Col>
                <Col xs={12} md>
                    <div className={styles.navListContainer}>
                    </div>
                </Col>
                <Col xs={12} md>
                    <div className={styles.navListContainer}>
                    </div>
                </Col>
                <Col xs={12} md>
                    <div className={styles.navListContainer}>
                    </div>
                </Col>
                <Col xs={12} md>
                </Col>
            </Row>
        </Grid>
        <div className={styles.bottom}>
            <p>© Copyright Kevin Curran 2020 - ☕️</p>
        </div>
    </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
