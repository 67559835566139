import React from "react"
import { Col, Grid, Row } from "react-flexbox-grid"

// Components

// Styles

// Images
import profileImage from '../../images/profileImage.jpg';

const HomePageTextContents = () => {
  return (
    <>
      <div style={pageTextContentContainer} className={"container-fluid"}>
        <div className={"row middle-lg"}>
            <Grid fluid>
              <Row>
                <Col style={headerImageStyle} xs={12} md={10} lg={8}>
                  Kevin Curran FCCA BA (hons)
                </Col>
              </Row>
              <Row>
                <div style={verticalSpacer}></div>
              </Row>
              <Row>
                <Col style={headerImageStyle} xs={12} md={10} lg={8}>
                  Hello welcome,
                </Col>
              </Row>
              {/*<Row>*/}
              {/*  <Col style={headerImageStyle} xs={12} md={10} lg={8}>*/}
              {/*    <p>The fact you have come this far indicates that you are considering hiring me (unless you typed in currants in pursuit of some Delia recipe and ended up with me -if so I think this is where we say goodbye).*/}
              {/*    </p>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>Let me set out what I can offer you.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>For all businesses large and small at the moment Covid-19 is both physically and commercially
                    the enemy. At the end of this epidemic many businesses will have gone to the wall taking a
                    breathtaking amount of livelihoods with them, not just direct owners and managers but staff
                    and ancillary providers as well.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>My mission is, where I can, to minimise this impact. There will be businesses that were
                    hemorrhaging customers or cash prior to Covid-19 and may well be beyond salvation. Where
                    business has any hope of limping through (bearing in mind some will thrive) I want to be the catalyst
                    that lights the way to the other side of this pandemic.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>What can I do?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>As a Management Accountant for over thirty years I have the skill set to analyse your business
                    model. In doing this I will be looking at the sources of income and costs - forecasting the futures
                    streams in relation to the National and local economic environments and applying a rigorous value
                    adding approach to decision making  and resource allocation.
                    I will be identifying key business drivers and what needs to happen for your Business to survive and
                    then- how do we make it happen. I am your Business Partner to help you through this.
                    I will furnish you with a report at the end of the analysis which will set out the options that you
                    have available to you, and depending on your decisions- your survival route.
                    This report may also assist you with other parties as it is a strategic plan for your Businesses future
                    in terms of projections that banks or other investors may take account of. Ultimately we will be
                    looking for the road back to profitability.
                    Not all businesses can be saved,  pared down organisation may be able to scramble on and return to
                    growth in the future,  for some it may already be too late but this in itself may deliver clarity.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>Curran Business Survival Consultancy has been  born in the Covid -19 epidemic environment and I will
                    commit all of my energy, creativity and enterprise to keeping your Business afloat through these
                    unprecedented times.
                  </p>
                </Col>
              </Row>
              <Row>
                <div style={verticalSpacer}></div>
              </Row>
              <Row>
                <Col style={headerImageStyle}xs={12} md={10} lg={8}>
                  <p>If you want a Business warrior on your side get in touch and lets meet the challenges together
                    head on.
                  </p>
                </Col>
              </Row>
              <Row>
                <div style={verticalSpacer}></div>
              </Row>
              <Row>
                <Col style={profileImageStyle} xs={9} md={3} lg={2}>
                  <img src={profileImage}/>
                </Col>
              </Row>
            </Grid>
        </div>
      </div>
    </>
);
}

const pageTextContentContainer = {
  marginTop: '20vh'

}

const verticalSpacer = {
  paddingBottom: "2vh"
}
const profileImageStyle = {
  paddingTop: "5vh",
  margin: 'auto'
}


const headerImageStyle = {
  margin: "auto"
}

HomePageTextContents.propTypes = {};

HomePageTextContents.defaultProps = {};

export default HomePageTextContents;
