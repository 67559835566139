import React from "react"
import PropTypes from "prop-types"

// Components
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

// Styles
import '../../global-styles/main.scss';

const Layout = ({ children }) => {

  return (
    <>
      <Nav />
      <div style={offsetFromNav}></div>
      <div style={layoutStyle}>
        { children }
      </div>
      <Footer />
    </>
  )
}

const layoutStyle = {
  minHeight: '100vh'
}

const offsetFromNav = {
  // marginTop: '10vh'
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;
