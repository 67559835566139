import React, { useEffect, useState, useRef } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import classNames from 'classnames';

// Helpers

// Components

// Styles
import styles from './Nav.module.scss';
import '../../global-styles/libs/burger.css';

// Images


const Nav = () => {

    const [getBackground, setBackground] = useState(false);
    const [navOpen, setNavOpen] = useState(false);

    const navEl = useRef()

    /**
     * Called when the scroll event is fired in the browser
     */
    const handleScroll = (e) => {
        if (window) {
            const { scrollY } = window;
            // Get the difference between the top of the nav element and the users current scroll pos
            // If it's less than or equal to 20 -> background should show
            let showBackground = scrollY > 40;
            setBackground(showBackground);
        }
    }

    /**
     * Effect hook called on init
     */
    useEffect(() => {
        if (window){
            window.addEventListener('scroll', handleScroll);
        }

        // Call handle scroll on initial load
        handleScroll()
        return () => {
            if (window){
                window.removeEventListener('scroll', handleScroll);
            }

        }
    }, [])

    return (
        <div className={styles.container}>
            <div ref={navEl} className={styles.fixed}>
                <nav className={classNames([styles.Header, {
                    [`${styles.background}`]: getBackground
                }])}>
                    <Grid fluid>
                        <Row>
                            <Col xs={6} sm={3}>
                                <h3>Kevin Curran</h3>
                            </Col>
                            <Col xs={6} sm={9} className={styles.actionCol}>
                                <h4>Business Survival Consultancy</h4>
                            </Col>
                        </Row>
                    </Grid>
                </nav>
            </div>
        </div>
    );
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
