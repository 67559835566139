
export const contactLinks = [
    {
        label: 'kcurranuk07@gmail.com',
        link: 'kcurranuk07@gmail.com'
    },

];



